import styled from "styled-components";

export const Container = styled.div`
  width: ${({ noContainer }) => (noContainer ? "100%" : "95%")};
  margin: ${({ noContainer }) => (noContainer ? "auto" : "2.5% auto")};
  padding: 0;

  .no-padding {
    padding: 0;
  }
`;
