import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";
import { getImagenField, subirImagen } from "core/utils/imagenValidator";
import { isUrl } from "core/util-functions/src/core/strings";
import { Column, Row, Typography } from "core/util-styled-components";
import { Avatar } from "antd";
import { faLink, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Item } from "../Documentos";

const actions = [
  {
    key: "view-documents",
    label: "Documentos",
    icon: faLink,
    type: "text",
  },
  {
    key: "edit",
    label: "Modificar",
    icon: faPen,
    type: "link",
  },
  {
    key: "delete",
    label: "Eliminar",
    icon: faTrash,
    type: "link",
    danger: true,
  },
];

const Trabajadores = () => {
  const nav = useNavigate();
  const TrabajadoresListar = useAxios("POST", "/trabajadores/listar");
  const TrabajadoresModificar = useAxios(
    "POST",
    "/trabajadores/modificar",
    {},
    false
  );
  const TrabajadoresCrear = useAxios("POST", "/trabajadores/crear", {}, false);
  const TrabajadoresEliminar = useAxios(
    "POST",
    "/trabajadores/eliminar",
    {},
    false
  );

  useEffect(() => {
    if (TrabajadoresCrear.responseCode === 200)
      TrabajadoresListar.sendRequest();
    if (TrabajadoresModificar.responseCode === 200)
      TrabajadoresListar.sendRequest();
    if (TrabajadoresEliminar.responseCode === 200)
      TrabajadoresListar.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    TrabajadoresCrear.responseCode,
    TrabajadoresModificar.responseCode,
    TrabajadoresEliminar.responseCode,
  ]);

  const onAction = async (action, data) => {
    if (action === "add") {
      let URLImage = "";
      if (data.Foto) {
        const Image = getImagenField(data.Foto);

        if (Image) {
          URLImage = await subirImagen(Image);
        }
      }

      const params = {
        ...data,
        DocumentoID: data.DNI,
        Img: URLImage,
        EsExtranjero: data.EsExtranjero ? 1 : 0,
        EsPasivo: data.EsPasivo ? 1 : 0,
      };

      console.log(params);
      TrabajadoresCrear.updateParams(params);
      TrabajadoresCrear.initRequest();
    }
    if (action === "view-documents") {
      nav(`/administrador/documentos/${data.IdTrabajador}`);
    }
    if (action === "edit") {
      let URLImage = "";
      if (isUrl(data.Foto)) {
        URLImage = data.Foto;
      } else {
        if (data.Foto) {
          const Image = getImagenField(data.Foto);
          if (Image) {
            URLImage = await subirImagen(Image);
          }
        }
      }

      const params = {
        ...data,
        DocumentoID: data.DNI,
        Img: URLImage,
        EsExtranjero: data.EsExtranjero ? 1 : 0,
        EsPasivo: data.EsPasivo ? 1 : 0,
      };

      TrabajadoresModificar.updateParams(params);
      TrabajadoresModificar.initRequest();
    }
    if (action === "delete") {
      TrabajadoresEliminar.updateParams({ ...data, id: data.IdTrabajador });
      TrabajadoresEliminar.initRequest();
    }
  };

  return (
    <CRUDPage
      title="Lista de trabajadores"
      data={TrabajadoresListar.data}
      form={[
        {
          name: "Datos personales",
          type: "title-separator",
        },
        { name: "DNI", type: "input", required: true },
        { name: "RUC", type: "input" },
        {
          name: "FechaNacimiento",
          type: "input",
          customProps: {
            type: "date",
          },
        },
        {
          name: "Nombres",
          type: "input",
          required: true,
          customProps: {
            required: true,
            spanCol: "span 3",
          },
        },

        {
          name: "ApellidoPaterno",
          type: "input",
          required: true,
          customProps: { required: true },
        },
        { name: "ApellidoMaterno", type: "input" },

        {
          name: "Foto",
          type: "imagen",
          customProps: {
            spanCol: "4/5",
            spanRow: "2/5",
          },
        },
        { name: "Datos adicionales", type: "title-separator" },
        {
          name: "DomicilioFiscal",
          type: "input",
          customProps: { spanCol: "span 2" },
        },
        {
          name: "Pais",
          type: "input",
        },
        { name: "EsExtranjero", type: "switch" },
        { name: "Telefono", type: "input" },
        {
          name: "CorreoElectronico",
          type: "input",
          customProps: { type: "email", spanCol: "span 3" },
        },
        { name: "Datos laborales", type: "title-separator" },
        {
          name: "FechaIngresoEntidad",
          type: "input",
          customProps: {
            type: "date",
          },
        },
        { name: "EsPasivo", type: "switch" },
      ]}
      rowRender={(data) => {
        return (
          <Row gap="10px">
            <Avatar src={data.Foto} />
            <Column>
              <Link
                style={{ alignSelf: "flex-start" }}
                to={`/administrador/documentos/${data.IdTrabajador}`}
              >
                <Typography fw="bold">{`${data.Nombres} ${data.ApellidoPaterno} ${data.ApellidoMaterno}`}</Typography>
              </Link>
              <Typography style={{ alignSelf: "flex-start" }}>
                <strong>DNI</strong>: {data.DNI} - <strong>RUC</strong>:{" "}
                {data.RUC}
              </Typography>
              <Typography style={{ alignSelf: "flex-start" }}>
                <Item name="Telefono">{data.Telefono}</Item>
                <Item name="Correo">{data.CorreoElectronico}</Item>
              </Typography>
            </Column>
          </Row>
        );
      }}
      customDesignProps={{ title: "trabajador", maSize: "100%", formCols: 4 }}
      onAction={onAction}
      useDefaultActions
      actions={actions}
      searchFields={[
        "Nombres",
        "ApellidoPaterno",
        "ApellidoMaterno",
        "DNI",
        "RUC",
      ]}
    />
  );
};

export default Trabajadores;
