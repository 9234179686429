import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./config/reportWebVitals";
import { HashRouter } from "react-router-dom";
import { UsuarioProvider } from "hooks/ContextUser";
import { ThemeProvider } from "styled-components";
import { Theming } from "theme/Theme";
import "antd/dist/antd.min.css";
import { AntdStyled } from "theme/AntdStyled";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <UsuarioProvider>
        <ThemeProvider theme={Theming}>
          <AntdStyled>
            <App />
          </AntdStyled>
        </ThemeProvider>
      </UsuarioProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
