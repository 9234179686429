import React, { useEffect, useState } from "react";
import { useUsuario } from "./ContextUser";

export const useRole = () => {
  const { usuario, getRole } = useUsuario();
  const [role, setRole] = useState({});

  useEffect(() => {
    const _role = getRole();
    setRole(_role ? _role : {});
    return () => {};
    // eslint-disable-next-line
  }, [usuario]);

  return role;
};
