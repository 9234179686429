import React from "react";
import Router from "../router/Router";
import Layout from "./components/skeleton/Layout";

function App() {
  return (
    <Layout>
      <Router />
    </Layout>
  );
}

export default App;
