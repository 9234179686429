import VerPDF from "App/components/molecules/VerPDF";
import CRUDPage from "App/components/skeleton/CRUDPage";
import { isUrl } from "core/util-functions/src/core/strings";
import { getField, subirPDF } from "core/utils/imagenValidator";
import useAxios from "hooks/useAxios";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React, { useEffect } from "react";
import { Item } from ".";

const DocumentosPanel = ({ IdTrabajador }) => {
  const DocumentosListar = useAxios("POST", "/documentos/listar", {
    IdTrabajador,
  });
  const TiposDocumentoListar = useAxios("POST", "/tipos-documento/listar");
  const DocumentoModificar = useAxios(
    "POST",
    "/documentos/modificar",
    {},
    false
  );
  const DocumentoCrear = useAxios("POST", "/documentos/crear", {}, false);
  const DocumentoEliminar = useAxios("POST", "/documentos/eliminar", {}, false);
  const { isTabletOrMobile } = useMediaQueryCustom();
  useEffect(() => {
    if (
      DocumentoCrear.responseCode === 200 ||
      DocumentoModificar.responseCode === 200 ||
      DocumentoEliminar.responseCode === 200
    ) {
      DocumentosListar.updateParams({ IdTrabajador });
      DocumentosListar.initRequest();
    }

    return () => {};
    //eslint-disable-next-line
  }, [
    DocumentoCrear.responseCode,
    DocumentoModificar.responseCode,
    DocumentoEliminar.responseCode,
  ]);

  const onAction = async (action, data) => {
    if (action === "add") {
      let URLPDF = "";
      const PDF = getField(data.PDF, "pdf");
      if (PDF) {
        URLPDF = await subirPDF(PDF);
      }

      let idTipoDocumento = "";
      if (data.TipoDocumento && data.TipoDocumento.length > 0) {
        idTipoDocumento = data.TipoDocumento[0].IdTipoDocumento;
      }

      const params = {
        ...data,
        idTrabajador: IdTrabajador,
        PDF: URLPDF,
        idTipoDocumento,
      };
      DocumentoCrear.updateParams(params);
      DocumentoCrear.initRequest();
    }
    if (action === "edit") {
      let URLPDF = "";

      if (isUrl(data.PDF)) {
        URLPDF = data.PDF;
      } else {
        const PDF = getField(data.PDF, "pdf");
        if (PDF) {
          URLPDF = await subirPDF(PDF);
        }
      }

      let idTipoDocumento = "";
      if (data.TipoDocumento.length > 0) {
        idTipoDocumento = data.TipoDocumento[0].IdTipoDocumento;
      }

      const params = {
        ...data,
        idTrabajador: IdTrabajador,
        PDF: URLPDF,
        idTipoDocumento,
      };
      DocumentoModificar.updateParams(params);
      DocumentoModificar.initRequest();
    }
    if (action === "delete") {
      DocumentoEliminar.updateParams({ data, id: data.IdDocumento });
      DocumentoEliminar.initRequest();
    }
  };

  return (
    <CRUDPage
      onAction={onAction}
      data={DocumentosListar.data}
      title="Lista de Documentos"
      noContainer
      useDefaultActions
      customDesignProps={{ formCols: 3, title: "Documento " }}
      rowRender={(data) => {
        return (
          <div>
            <Item name="Tipo de documento">
              {data.TipoDocumento &&
                TiposDocumentoListar.data.find(
                  (el) => el.IdTipoDocumento === data.TipoDocumento
                )?.Descripcion}
            </Item>
            <Item name="Descripcion">{data.Descripcion}</Item>
            <Item name="Fecha">
              {new Date(data.Fecha).toISOString().split("T")[0]}
            </Item>
            <Item name="PDF">
              <VerPDF urlPDF={data.PDF}>
                {isTabletOrMobile ? "Ver PDF" : data.PDF}
              </VerPDF>
            </Item>
          </div>
        );
      }}
      form={[
        {
          name: "Informacion general",
          type: "title-separator",
        },
        { name: "Descripcion", type: "input" },
        { name: "Fecha", type: "input", customProps: { type: "date" } },
        {
          name: "TipoDocumento",
          type: "dropdown",
          optionsDropdown: TiposDocumentoListar.data,
          optionLabel: "Descripcion",
          optionValue: "IdTipoDocumento",
          customProps: {
            loading: TiposDocumentoListar.loading,
          },
        },
        {
          name: "Contenido multimedia",
          type: "title-separator",
        },
        {
          name: "PDF",
          type: "input",
          required: true,
          customProps: { type: "file", spanCol: "span 3" },
        },
      ]}
      searchFields={["Descripcion", "PDF"]}
    />
  );
};

export default DocumentosPanel;
