import React from "react";
import Logo from "App/components/atoms/Logo";
import { Div, Row, Typography } from "core/util-styled-components";
import { useUsuario } from "hooks/ContextUser";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import { UserName } from "../styled";
import { Avatar, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Toolbar = ({ setVisible }) => {
  const { usuario } = useUsuario();
  const { isTabletOrMobile } = useMediaQueryCustom();
  return (
    <Div
      style={{
        backgroundColor: "white",
        zIndex: 10,
        padding: isTabletOrMobile ? "0.05em .1em" : "0.6em 1.3em",
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.1)",
        width: "100%",
        position: "sticky",
        top: 0,
      }}
    >
      <Row
        justifyContent="space-between"
        padding={isTabletOrMobile ? ".75em 5%" : "20px 3em"}
      >
        <Logo />
        {usuario && (
          <Row gap="1em">
            <UserName gap=".5em" onClick={() => setVisible(true)}>
              <Avatar
                src={
                  usuario.user.ruta && usuario.user.ruta !== "undefined"
                    ? usuario.user.ruta
                    : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                }
              />
              {usuario && !isTabletOrMobile && (
                <Typography>{usuario.user.user}</Typography>
              )}
            </UserName>
            {!isTabletOrMobile && (
              <Button
                type="primary"
                shape="circle"
                onClick={() => setVisible(true)}
              >
                <FontAwesomeIcon icon={faBars} />
              </Button>
            )}
          </Row>
        )}
      </Row>
    </Div>
  );
};

export default Toolbar;
