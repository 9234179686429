import { Button, Spin } from "antd";
import { Row } from "core/util-styled-components";
import { convertToAButton, viewPDFBlob } from "core/utils/pdfReader";
import { fetchGET } from "core/utils/simpleAxios";
import { useUsuario } from "hooks/ContextUser";
import React, { useState } from "react";

const VerPDF = ({ urlPDF, children }) => {
  const { tokenInterceptor } = useUsuario();
  const [loading, setLoading] = useState(false);
  const getPDF = async () => {
    try {
      setLoading(true);
      const response = await fetchGET(urlPDF, tokenInterceptor, "", {
        responseType: "blob",
      });
      const fileURL = viewPDFBlob(response.data);
      convertToAButton(fileURL);
      setLoading(false);
    } catch (error) {
      return false;
    }
  };

  return (
    <div>
      <Button type="link" onClick={getPDF} size="small">
        <Row gap="10px">
          {children} {loading && <Spin size="small" />}
        </Row>
      </Button>
    </div>
  );
};

export default VerPDF;
