import React from "react";
import { Button, Card, Input, Modal, Table } from "antd";
import { useState, useEffect } from "react";
import { Container } from "../Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Column } from "core/util-styled-components";
import { Formulario } from "core/ts_formulario_model";
import { generateMapTable } from "core/utils/tables";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import { useRole } from "hooks/useRole";

const defaultForm = [{ name: "Descripcion", type: "input" }];

const _defaultActions = [
  {
    key: "edit",
    label: "Modificar",
    icon: faPen,
    type: "link",
  },
  {
    key: "delete",
    label: "Eliminar",
    icon: faTrash,
    type: "link",
    danger: true,
  },
];

function CRUDPage({
  form = defaultForm,
  title = "Listado de registros",
  formFilter,
  rowRender = (row) => row.Descripcion,
  data = [],
  actions = _defaultActions,
  onAction = (action, payload) => {},
  useDefaultActions = false,
  cols = 1,
  onFilter = (filter) => {},
  readOnly = false,
  customDesignProps = {},
  noContainer = false,
  searchFields = ["Descripcion"],
}) {
  const { isAdmin, isValid } = useRole();

  const [visible, setVisible] = useState(false);
  const [buttonNuevoVisible, setButtonNuevoVisible] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [registroSeleccionado, setRegistroSeleccionado] = useState();
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [searchField, setSearchField] = useState("");

  const defaultActions = (action, payload) => {
    switch (action) {
      case "edit":
        if (isAdmin) {
          setRegistroSeleccionado(payload);
          setModalVisible(true);
        }
        break;
      case "delete":
        if (isAdmin) {
          Modal.confirm({
            title: "Eliminar registro",
            content: `¿Está seguro que desea eliminar el registro "${
              payload.Descripcion ? payload.Descripcion : ""
            }"?`,
            onOk: () => {
              onAction("delete", payload);
            },
          });
        }
        break;
      case "add":
        setRegistroSeleccionado(null);
        setModalVisible(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!isTabletOrMobile) {
      setButtonNuevoVisible(true);
    } else {
      setButtonNuevoVisible(!visible);
    }

    return () => {};
  }, [visible, isTabletOrMobile]);

  return (
    <Container noContainer={noContainer}>
      <Card
        title={title}
        extra={
          !readOnly && (
            <Row gap="10px">
              {!visible && (
                <Button
                  type="link"
                  shape="round"
                  onClick={() => setVisible(true)}
                >
                  <Row gap="10px">
                    <FontAwesomeIcon icon={faSearch} />{" "}
                    {!isTabletOrMobile && <span>Buscar</span>}
                  </Row>
                </Button>
              )}
              {visible && (
                <Input
                  prefix={
                    <Button
                      type="link"
                      shape="round"
                      onClick={() => setVisible(true)}
                    >
                      <Row gap="10px">
                        <FontAwesomeIcon icon={faSearch} />{" "}
                      </Row>
                    </Button>
                  }
                  autoFocus={true}
                  placeholder="Buscar..."
                  style={{
                    border: "none",
                    borderBottom: "1px dashed #c3c3c3",
                    padding: 0,
                  }}
                  onBlur={() => setVisible(Boolean(searchField))}
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value)}
                  allowClear
                />
              )}
              {buttonNuevoVisible && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => {
                    if (!useDefaultActions) {
                      return onAction("add", null);
                    }
                    defaultActions("add", null);
                  }}
                >
                  <Row gap="5px">
                    <FontAwesomeIcon icon={faPlus} />
                    <strong>Agregar registro</strong>
                  </Row>
                </Button>
              )}
            </Row>
          )
        }
      >
        <Table
          dataSource={
            searchField
              ? data.filter((el) => {
                  return searchFields.reduce(
                    (acc, currentVal) =>
                      acc ||
                      el[currentVal]
                        ?.toLowerCase()
                        .includes(searchField.toLowerCase()),
                    false
                  );
                })
              : data
          }
          columns={generateMapTable([
            {
              className: "no-padding",
              render: (_, row) => {
                return (
                  <Row
                    justifyContent="space-between"
                    style={{
                      borderBottom: "dashed 1px #f0f0f0",
                      padding: 10,
                      borderRadius: 0,
                    }}
                  >
                    {rowRender(row)}
                    {!readOnly && (
                      <Column gap="5px">
                        {
                          // @ts-ignore
                          actions.map((action) => (
                            <Button
                              type={action.type}
                              danger={action.danger}
                              onClick={() => {
                                if (useDefaultActions) {
                                  if (
                                    action.key === "edit" ||
                                    action.key === "delete" ||
                                    action.key === "add"
                                  ) {
                                    return defaultActions(action.key, row);
                                  }
                                  return onAction(action.key, row);
                                }
                                onAction(action.key, row);
                              }}
                              disabled={
                                !isAdmin &&
                                (action.key === "delete" ||
                                  action.key === "edit")
                              }
                            >
                              <Row gap={isTabletOrMobile ? 0 : "10px"}>
                                <FontAwesomeIcon icon={action.icon} />
                                {!isTabletOrMobile && (
                                  <span>{action.label}</span>
                                )}
                              </Row>
                            </Button>
                          ))
                        }
                      </Column>
                    )}
                  </Row>
                );
              },
            },
          ])}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={`${Boolean(registroSeleccionado) ? "Editar" : "Crear"} ${
          customDesignProps.title || "registro"
        }`}
        onCancel={() => {
          setModalVisible(false);
          setRegistroSeleccionado(null);
        }}
        bodyStyle={{
          padding: 5,
        }}
        width={customDesignProps.mbSize || isTabletOrMobile ? "100%" : "50%"}
        centered
        footer={null}
        destroyOnClose
      >
        <Formulario
          onSubmit={(data) => {
            if (Boolean(registroSeleccionado)) {
              onAction("edit", { ...registroSeleccionado, ...data });
            } else {
              onAction("add", data);
            }
            setModalVisible(false);
          }}
        >
          <Formulario.FormControls
            cols={customDesignProps.formCols || cols}
            buttonSubmitLargerAndOnly
            submitLabel={"Guardar"}
            onCancel={() => setVisible(false)}
            inputs={
              Boolean(registroSeleccionado)
                ? form.map((el) => {
                    return {
                      ...el,
                      defaultValue: getValue(el, registroSeleccionado),
                      required:
                        el.type === "input" &&
                        el.customProps &&
                        el.customProps.type === "file"
                          ? false
                          : el.required,
                    };
                  })
                : form
            }
          />
        </Formulario>
      </Modal>
    </Container>
  );
}

const getValueDropdown = (props, value) => {
  if (props.customProps && props.customProps.finderName) {
    const finder = props.customProps.finderName;
    return props.optionsDropdown.filter((el) => el[finder] === value);
  }

  return props.optionsDropdown.filter((el) => el[props.optionValue] === value);
};

const getValue = (el, registroSeleccionado) => {
  if (el.type === "dropdown") {
    return getValueDropdown(el, registroSeleccionado[el.name]);
  }

  if (el.type === "input" && el.customProps && el.customProps.type === "date") {
    return Boolean(registroSeleccionado[el.name])
      ? new Date(registroSeleccionado[el.name]).toISOString().split("T")[0]
      : "";
  }

  if (el.type === "switch") {
    return Boolean(registroSeleccionado[el.name]);
  }

  return registroSeleccionado[el.name];
};

export default CRUDPage;
