export const viewPDFBlob = (blob) => {
  const file = new Blob([blob], { type: "application/pdf" });
  let fileURL = URL.createObjectURL(file);
  return fileURL;
};

export const convertToAButton = (fileURL) => {
  let element = document.createElement("a");
  element.setAttribute("href", fileURL);
  element.setAttribute("target", "_blank");

  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
