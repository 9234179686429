import VerPDF from "App/components/molecules/VerPDF";
import CRUDPage from "App/components/skeleton/CRUDPage";
import { isUrl } from "core/util-functions/src/core/strings";
import { getField, subirPDF } from "core/utils/imagenValidator";
import useAxios from "hooks/useAxios";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React, { useEffect } from "react";
import { Item } from ".";

const ContratosPanel = ({ IdTrabajador }) => {
  const ContratosListar = useAxios("POST", "/contratos/listar", {
    IdTrabajador,
  });
  const TiposDocumentoListar = useAxios("POST", "/tipos-contrato/listar");

  const DocumentoModificar = useAxios(
    "POST",
    "/contratos/modificar",
    {},
    false
  );
  const DocumentoCrear = useAxios("POST", "/contratos/crear", {}, false);
  const DocumentoEliminar = useAxios("POST", "/contratos/eliminar", {}, false);
  const { isTabletOrMobile } = useMediaQueryCustom();
  useEffect(() => {
    if (
      DocumentoCrear.responseCode === 200 ||
      DocumentoModificar.responseCode === 200 ||
      DocumentoEliminar.responseCode === 200
    ) {
      ContratosListar.updateParams({ IdTrabajador });
      ContratosListar.initRequest();
    }

    return () => {};
    //eslint-disable-next-line
  }, [
    DocumentoCrear.responseCode,
    DocumentoModificar.responseCode,
    DocumentoEliminar.responseCode,
  ]);

  const onAction = async (action, data) => {
    if (action === "add") {
      let URLPDF = "";
      const PDF = getField(data.PDF, "pdf");
      if (PDF) {
        URLPDF = await subirPDF(PDF);
      }

      let tipoContrato = "";
      if (data.TipoContrato && data.TipoContrato.length > 0) {
        tipoContrato = data.TipoContrato[0].IdTipoContrato;
      }

      const params = {
        ...data,
        idTrabajador: IdTrabajador,
        PDF: URLPDF,
        tipoContrato,
      };
      DocumentoCrear.updateParams(params);
      DocumentoCrear.initRequest();
    }
    if (action === "edit") {
      let URLPDF = "";

      if (isUrl(data.PDF)) {
        URLPDF = data.PDF;
      } else {
        const PDF = getField(data.PDF, "pdf");
        if (PDF) {
          URLPDF = await subirPDF(PDF);
        }
      }

      let tipoContrato = "";
      if (data.TipoContrato && data.TipoContrato.length > 0) {
        tipoContrato = data.TipoContrato[0].IdTipoContrato;
      }

      const params = {
        ...data,
        idTrabajador: IdTrabajador,
        PDF: URLPDF,
        tipoContrato,
      };
      DocumentoModificar.updateParams(params);
      DocumentoModificar.initRequest();
    }
    if (action === "delete") {
      DocumentoEliminar.updateParams({ ...data, id: data.IdContrato });
      DocumentoEliminar.initRequest();
    }
  };

  return (
    <CRUDPage
      data={ContratosListar.data}
      title="Lista de contrato"
      noContainer
      useDefaultActions
      customDesignProps={{ formCols: 3, title: "Contratos " }}
      rowRender={(data) => {
        return (
          <div>
            <Item name="Tipo de contrato">
              {data.TipoContrato &&
                TiposDocumentoListar.data.find(
                  (el) => el.IdTipoContrato === data.TipoContrato
                )?.Descripcion}
            </Item>
            <Item name="Fecha de inicio">
              {new Date(data.FechaInicio).toISOString().split("T")[0]}
            </Item>
            <Item name="Fecha de fin">
              {new Date(data.FechaFin).toISOString().split("T")[0]}
            </Item>
            <Item name="PDF">
              <VerPDF urlPDF={data.PDF}>
                {isTabletOrMobile ? "Ver PDF" : data.PDF}
              </VerPDF>
            </Item>
          </div>
        );
      }}
      onAction={onAction}
      form={[
        {
          name: "Informacion general",
          type: "title-separator",
        },
        {
          name: "TipoContrato",
          type: "dropdown",
          optionsDropdown: TiposDocumentoListar.data,
          optionLabel: "Descripcion",
          optionValue: "IdTipoContrato",
          customProps: {
            loading: TiposDocumentoListar.loading,
          },
        },
        { name: "FechaInicio", type: "input", customProps: { type: "date" } },
        { name: "FechaFin", type: "input", customProps: { type: "date" } },
        {
          name: "Contenido multimedia",
          type: "title-separator",
        },
        {
          name: "PDF",
          type: "input",
          required: true,
          customProps: { type: "file", spanCol: "span 3" },
        },
      ]}
      searchFields={["PDF"]}
    />
  );
};

export default ContratosPanel;
