import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getUrl = (keyPath = []) => {
  const reverse = keyPath.reverse();
  return reverse.reduce(
    (acc, curr) => `${acc}${curr === "rc-menu-more" ? "" : curr}/`,
    ""
  );
};
export const mapAdmin = {
  label: "Administrador",
  key: "administrador",
  icon: <FontAwesomeIcon icon={faCogs} className="icon-primary" />,
  children: [
    {
      label: "Trabajadores",
      key: "trabajadores",
    },
    {
      label: "Tipos de documento",
      key: "tipos-documento",
    },
    {
      label: "Tipos de contrato",
      key: "tipos-contrato",
    },
    // {
    //   label: "Usuarios",
    //   key: "usuarios",
    // },
  ],
};
