import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "App/pages/Login";
import TipoDocumento from "App/pages/Admin/TipoDocumento";
import TipoContrato from "App/pages/Admin/TipoContrato";
import Trabajadores from "App/pages/Admin/Trabajadores";
import Usuarios from "App/pages/Admin/Usuarios";
import Documentos from "App/pages/Admin/Documentos";
import Contratos from "App/pages/Admin/Contratos";
import { useRole } from "hooks/useRole";

const Router = () => {
  const { isValid } = useRole();

  return (
    <Routes>
      <Route path="/">
        <Route index element={isValid ? <Trabajadores /> : <Login />} />
        <Route path="login" element={<Login />} />
        {isValid && (
          <Route path="administrador">
            <Route path="documentos/:id" element={<Documentos />} />
            <Route path="tipos-documento" element={<TipoDocumento />} />
            <Route path="tipos-contrato" element={<TipoContrato />} />
            <Route path="contratos/:id" element={<Contratos />} />
            <Route path="trabajadores" element={<Trabajadores />} />
            <Route path="usuarios" element={<Usuarios />} />
          </Route>
        )}
      </Route>
    </Routes>
  );
};

export default Router;
