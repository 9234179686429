import { Avatar, Card, Tag } from "antd";
import { Container } from "App/components/skeleton/Container";
import { Column, Grid, Row, Typography } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
import useAxios from "hooks/useAxios";
import { useMediaQueryCustom } from "hooks/useMediaQueryCustom";
import React from "react";
import { useParams } from "react-router-dom";
import ContratosPanel from "./Contratos";
import DocumentosPanel from "./Documentos";

const Documentos = () => {
  const { id } = useParams();

  const { isTabletOrMobile } = useMediaQueryCustom();

  const Trabajador = useAxios("POST", "/trabajadores/consultar", {
    IdTrabajador: id,
  });

  return (
    <Column width="95%" margin="20px auto" gap="15px">
      {Trabajador.data.map((data) => (
        <Card
          title={
            <Row gap="10px">
              <Avatar src={data.Foto} />
              <Typography fw="bold">
                {`${data.Nombres} ${data.ApellidoPaterno} ${data.ApellidoMaterno}
                `}
              </Typography>
            </Row>
          }
          extra={
            data.EsPasivo ? (
              <Tag>Ex-empleado</Tag>
            ) : (
              <Tag color="green">Empleado actual</Tag>
            )
          }
        >
          <Grid cols={isTabletOrMobile ? 1 : 4}>
            <GridChild>
              <Column>
                <Item name="DNI">{data.DNI}</Item>
                <Item name="RUC">{data.RUC}</Item>
                <Item name="Telefono">{data.Telefono}</Item>
                <Item name="Correo">{data.CorreoElectronico}</Item>
              </Column>
            </GridChild>

            <GridChild>
              <Column>
                <Item name="Fecha de Nacimiento">
                  {new Date(data.FechaNacimiento).toISOString().split("T")[0]}
                </Item>
                <Item name="Fecha de Ingreso a la entidad">
                  {
                    new Date(data.FechaIngresoEntidad)
                      .toISOString()
                      .split("T")[0]
                  }
                </Item>
                <Item name="Fecha de registro">
                  {new Date(data.FechaRegistro).toISOString().split("T")[0]}
                </Item>
              </Column>
            </GridChild>
            <GridChild spanCol={isTabletOrMobile ? "span 1" : "span 2"}>
              <Column>
                <Item name="Extranjero">
                  {data.EsExtranjero === 1 ? "Si" : "No"}
                </Item>
                <Item name="Pais">{data.Pais}</Item>
                <Item name="Domicilio">{data.DomicilioFiscal}</Item>
              </Column>
            </GridChild>
          </Grid>
        </Card>
      ))}

      <DocumentosPanel IdTrabajador={id} />
      <ContratosPanel IdTrabajador={id} />
    </Column>
  );
};

export const Item = ({ name = "", children }) => {
  return (
    <Row gap={name ? ".25em" : 0} alignSelf="flex-start">
      <Typography fw="bold" style={{ alignSelf: "flex-start" }}>
        {name ? `${name} :` : ""}{" "}
      </Typography>
      <Typography style={{ alignSelf: "flex-start" }}>
        {children ? children : "No definido"}
      </Typography>
    </Row>
  );
};

export default Documentos;
