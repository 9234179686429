import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";

const TipoContrato = () => {
  const TipoDocumentoListar = useAxios("POST", "/tipos-contrato/listar");
  const TipoDocumentoModificar = useAxios(
    "POST",
    "/tipos-contrato/modificar",
    {},
    false
  );
  const TipoDocumentoCrear = useAxios(
    "POST",
    "/tipos-contrato/crear",
    {},
    false
  );
  const TipoDocumentoEliminar = useAxios(
    "POST",
    "/tipos-contrato/eliminar",
    {},
    false
  );

  useEffect(() => {
    if (TipoDocumentoCrear.responseCode === 200)
      TipoDocumentoListar.sendRequest();
    if (TipoDocumentoModificar.responseCode === 200)
      TipoDocumentoListar.sendRequest();
    if (TipoDocumentoEliminar.responseCode === 200)
      TipoDocumentoListar.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    TipoDocumentoCrear.responseCode,
    TipoDocumentoModificar.responseCode,
    TipoDocumentoEliminar.responseCode,
  ]);

  const onAction = (action, data) => {
    if (action === "add") {
      TipoDocumentoCrear.updateParams(data);
      TipoDocumentoCrear.initRequest();
    }
    if (action === "edit") {
      TipoDocumentoModificar.updateParams(data);
      TipoDocumentoModificar.initRequest();
    }
    if (action === "delete") {
      TipoDocumentoEliminar.updateParams(data);
      TipoDocumentoEliminar.initRequest();
    }
  };

  return (
    <CRUDPage
      title="Lista de contrato"
      data={TipoDocumentoListar.data}
      useDefaultActions
      onAction={onAction}
    />
  );
};

export default TipoContrato;
