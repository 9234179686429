import React, { useEffect } from "react";
import CRUDPage from "App/components/skeleton/CRUDPage";
import useAxios from "hooks/useAxios";

const TipoDocumento = () => {
  const TipoDocumentoListar = useAxios("POST", "/tipos-documento/listar");
  const TipoDocumentoModificar = useAxios(
    "POST",
    "/tipos-documento/modificar",
    {},
    false
  );
  const TipoDocumentoCrear = useAxios(
    "POST",
    "/tipos-documento/crear",
    {},
    false
  );
  const TipoDocumentoEliminar = useAxios(
    "POST",
    "/tipos-documento/eliminar",
    {},
    false
  );

  useEffect(() => {
    if (TipoDocumentoCrear.responseCode === 200)
      TipoDocumentoListar.sendRequest();
    if (TipoDocumentoModificar.responseCode === 200)
      TipoDocumentoListar.sendRequest();
    if (TipoDocumentoEliminar.responseCode === 200)
      TipoDocumentoListar.sendRequest();

    return () => {};
    //eslint-disable-next-line
  }, [
    TipoDocumentoCrear.responseCode,
    TipoDocumentoModificar.responseCode,
    TipoDocumentoEliminar.responseCode,
  ]);

  const onAction = (action, data) => {
    if (action === "add") {
      TipoDocumentoCrear.updateParams(data);
      TipoDocumentoCrear.initRequest();
    }
    if (action === "edit") {
      TipoDocumentoModificar.updateParams(data);
      TipoDocumentoModificar.initRequest();
    }
    if (action === "delete") {
      TipoDocumentoEliminar.updateParams({ ...data, id: data.IdTipoDocumento });
      TipoDocumentoEliminar.initRequest();
    }
  };
  return (
    <CRUDPage
      title="Tipos de documentos"
      data={TipoDocumentoListar.data}
      useDefaultActions
      onAction={onAction}
    />
  );
};

export default TipoDocumento;
