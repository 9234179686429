import { message } from "antd";
import axios from "axios";

// return field with type imagen
export const getImagenField = (fieldList, type = "image") => {
  let result = null;

  Object.keys(fieldList).forEach((key) => {
    if (fieldList[key].type.includes(type)) {
      result = fieldList[key];
      return result;
    }
  });

  return result;
};

export const getField = (fieldList, type) => {
  let result = null;
  Object.keys(fieldList).forEach((key) => {
    if (fieldList[key].type.includes(type)) {
      result = fieldList[key];
      return result;
    }
  });

  return result;
};

export const subirImagen = async (file) => {
  const formData = new FormData();
  formData.append("ArticleImage", file);
  const { data } = await axios.post(
    "https://media.hsantarosa.gob.pe/uploadImg.php",
    formData
  );
  console.log(data);
  return data.url;
};

export const subirPDF = async (file) => {
  const formData = new FormData();
  formData.append("FilePDF", file);
  message.loading("Subiendo archivo...");
  const { data } = await axios.post(
    "https://media.hsantarosa.gob.pe/uploadLockPDF.php",
    formData
  );
  if (data.error) {
    message.error("Error al subir el archivo");
  } else {
    message.success("Archivo subido");
  }

  return data.url;
};
