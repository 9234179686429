import { Column } from "core/util-styled-components";
import React, { useState } from "react";
import DrawerX from "./Drawer";
import Toolbar from "./Toolbar";

const Layout = ({ children }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Column>
      <DrawerX visible={visible} setVisible={setVisible} />
      <Toolbar setVisible={setVisible} />
      {children}
    </Column>
  );
};

export default Layout;
